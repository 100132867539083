<template>
  <div class="report_work">
    <van-nav-bar
      title="新建报工"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <scroll class="report_work_scroll" :onLoadMore="onLoadMore">
      <van-form @submit="onSubmit" class="report_work_from">
        <div class="report_work_from_header">
          <van-field
            v-model="woCode"
            required
            readonly
            name="工单编号"
            label="工单编号"
            placeholder="请选择工单编号"
          />
          <van-field
            v-model="wpName"
            required
            readonly
            name="工序名称"
            label="工序名称"
            placeholder="请选择工序名称"
          />
          <van-field
            v-model="productName"
            required
            readonly
            name="产品名称"
            label="产品名称"
            placeholder="请选择产品名称"
          />
        </div>
        <div class="report_work_input">报工录入</div>
        <div class="report_work_input_details">
          <van-field
            class="report_work_personnel"
            v-model="personnel"
            required
            readonly
            name="生产人员"
            label="生产人员"
            placeholder="点击选择生产人员"
            @click="personnelShow = true"
          />
          <div class="report_work_numb">
            <van-field
              v-model="goodProductCounts"
              required
              type="digit"
              name="良品数"
              label="良品数"
              placeholder="请输入"
            />
            <van-field
              v-model="badProductCounts"
              required
              type="digit"
              name="不良品数"
              label="不良品数"
              placeholder="请输入"
            />
            <van-field
              v-model="total"
              readonly
              type="digit"
              name="总数"
              label="总数"
              placeholder="总数"
            />
            <van-field
              class="report_work_personnel"
              v-model="defectiveText"
              readonly
              name="不良品数原因"
              label="不良品数原因"
              placeholder="点击选择不良品数原因"
              @click="defectiveShow = true"
            />
          </div>
        </div>
        <div class="report_work_input_time">
          <van-field
            v-model="planStartTime"
            readonly
            clickable
            label="开始时间"
            left-icon=" icon icon-bitian"
            placeholder="点击选择时间"
            @click="startTimeShow = true"
          />
          <van-field
            v-model="planEndTime"
            readonly
            clickable
            label="结束时间"
            left-icon=" icon icon-bitian"
            placeholder="点击选择时间"
            @click="endTimeShow = true"
          />
        </div>
        <div class="report_work_achievements">
          <div class="report_work_achievements_item">
            <div class="achievements">工资绩效</div>
            <div class="refresh" @click="headleItemRefresh">
              <span class="icon icon-shuaxin"></span>刷新
            </div>
          </div>
        </div>
        <div class="report_work_calculation">
          <div v-if="achieveList.type == 0">
            <div class="report_work_wages">
              <span>计件工价:</span>
              <span>{{ achieveList.price }}</span>
              <span class="icon icon-qiehuan"></span>
              <span>{{ goodProductCounts }}</span>
            </div>
            <div class="report_work_total">
              <span>计件工资:</span>
              <span class="report_work_total_yuan">{{ totalPrice }}元</span>
            </div>
          </div>
          <div v-else-if="achieveList.type == 1">
            <div class="report_work_wages">
              <span>计时工价:</span>
              <span>{{ achieveList.price }}</span>
              <span class="icon icon-qiehuan"></span>
              <span></span>
            </div>
            <div class="report_work_total">
              <span>计时工资:</span>
              <span class="report_work_total_yuan">{{ totalPrice }}元</span>
            </div>
          </div>
          <div v-else>
            <div class="report_work_wages">
              <span>计件工价:</span>
              <span>0</span>
              <span class="icon icon-qiehuan"></span>
            </div>
            <div class="report_work_total">
              <span>计件工资:</span>
            </div>
          </div>
        </div>
        <customise ref="customise" :defType="defType"></customise>
        <div class="report_work_input">审批</div>
        <van-field
          class="report_work_approval"
          v-model="roleName"
          required
          readonly
          clickable
          name="审批人员"
          label="审批人员"
          placeholder="点击选择审批人员"
          @click="approvalShow = true"
        />
        <div style="margin: 30px 16px 16px 16px" class="submit">
          <van-button round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
    </scroll>
    <van-popup
      v-model="startTimeShow"
      :close-on-click-overlay="false"
      position="bottom"
    >
      <van-datetime-picker
        type="datetime"
        @confirm="selectStartTime"
        @cancel="startTimeShow = false"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
    <van-popup
      v-model="endTimeShow"
      :close-on-click-overlay="false"
      position="bottom"
    >
      <van-datetime-picker
        type="datetime"
        @confirm="selectEndTime"
        @cancel="endTimeShow = false"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>

    <van-popup
      v-model="personnelShow"
      position="bottom"
      round
      :close-on-click-overlay="false"
      :style="{ height: '30%' }"
      class="jurisdiction"
    >
      <div class="jurisdiction_headle">
        <div @click="personnelShow = false">取消</div>
        <div @click="personnelShow = false">确定</div>
      </div>
      <van-radio-group v-model="personnel">
        <van-radio
          :name="item.nickname"
          v-for="item in rwPowerOojList"
          :key="item.id"
          >{{ item.nickname }}</van-radio
        >
      </van-radio-group>
    </van-popup>
    <van-popup
      v-model="defectiveShow"
      position="bottom"
      round
      :close-on-click-overlay="false"
      :style="{ height: '30%' }"
      class="jurisdiction"
    >
      <div class="jurisdiction_headle">
        <div @click="defectiveShow = false">取消</div>
        <div @click="selectDefective(defectiveList)">确定</div>
      </div>
      <van-checkbox-group v-model="defectiveList">
        <van-checkbox
          :name="item.defectiveName"
          v-for="item in defectiveItemObjList"
          :key="item.id"
          >{{ item.defectiveName }}</van-checkbox
        >
      </van-checkbox-group>
    </van-popup>
    <van-popup
      v-model="approvalShow"
      position="bottom"
      round
      :close-on-click-overlay="false"
      :style="{ height: '30%' }"
      class="jurisdiction"
    >
      <div class="jurisdiction_headle">
        <div @click="approvalShow = false">取消</div>
        <div @click="approvalShow = false">确定</div>
      </div>
      <van-radio-group v-model="roleName">
        <van-radio
          :name="item.nickname"
          v-for="item in approvalList"
          :key="item.id"
          >{{ item.nickname }}</van-radio
        >
      </van-radio-group>
    </van-popup>
  </div>
</template>
<script>
import scroll from "../../components/scroll/index";
import customise from "@/components/customise/index";
import { ChangePageTitle } from "../../common/js/upTitle";
import { mapActions, mapGetters } from "vuex";
import {
  getProcedureDeList,
  getEwoWorkOrderList,
  getEwoAchieveConfigParam,
  getSysUserList,
  addWork,
} from "@/api/work.js";
export default {
  computed: {
    ...mapGetters(["workIngId", "departmentCode"]),
    minDate() {
      let curDate = new Date().getTime();
      let one = 7 * 24 * 3600 * 1000;
      let oneYear = curDate - one;
      return new Date(oneYear);
    },
  },
  watch: {
    planEndTime: {
      handler: function (val) {
        var _that = this;
        if (val) {
          if (_that.planStartTime > val) {
            _that.planEndTime = "";
            _that.$toast.fail("结束时间小于开始时间，请重新选择");
          }
        }
      },
    },
  },
  data() {
    return {
      defType: 4, //报工
      total: "", //包公总数
      woCode: "", //工单编号
      wpName: "", //工序名称
      productName: "", //产品名称
      goodProductCounts: 0, //良品数
      badProductCounts: 0, //不良品数
      defectiveItemObjList: "", //所有不良品项
      startTimeShow: false, //开始时间弹窗
      endTimeShow: false, //开始时间弹窗
      personnelShow: false, //选择生产人员弹窗
      approvalShow: false, //审批弹窗
      woCodeShow: false, //工单弹窗
      defectiveShow: false,
      planStartTime: "", //开始时间
      planEndTime: "", //结束时间
      rwPowerOojList: [], //所有用户报工权限
      personnel: "", //生产人员name
      defectiveList: [], //选择不良品项
      defectiveText: "", //
      maxDate: new Date(),
      achieveList: [], //报工所需的绩效模型
      productCode: "", //产品code
      wpCode: "", //工序编号
      totalPrice: 0, //总工资
      workId: "", //工单id
      woId: "", //工单工序ID
      roleName: "", //审批人
      approvalList: [], //所有审批人员
    };
  },
  created() {
    ChangePageTitle("新建报工");
    this.getSysUserList();
    this.getProcedureDeList(this["workIngId"]);
  },
  methods: {
    ...mapActions(["work/closeWorkIngId"]),
    onClickLeft() {
      this.$router.replace({ path: "/workDetails" });
      this["work/closeWorkIngId"]();
    },
    //从工单详情
    async getProcedureDeList(id) {
      this.woId = id;
      try {
        const res = await getProcedureDeList(id);
        this.woCode = res.woCode;
        this.wpName = res.wpName;
        this.defectiveItemObjList = res.defectiveItemObjList;
        this.rwPowerOojList = res.rwPowerOojList;
        this.wpCode = res.wpCode;
        var departmentCode = this["departmentCode"];
        //根据工单编号查询产品名称
        var params = {
          departmentCode: departmentCode,
          woStateList: [1],
        };
        const res1 = await getEwoWorkOrderList(params);
        const index = res1.findIndex((v) => v.woCode == res.woCode);
        this.workId = res1[index].id;
        this.productName = res1[index].productName;
        this.productCode = res1[index].productCode;
        this.getEwoAchieveConfigParam();
      } catch (err) {
        console.log(err);
      }
    },
    //查询绩效
    async getEwoAchieveConfigParam() {
      //查询报工所需的绩效模型
      var list = {
        departmentCode: this["departmentCode"],
        productCode: this.productCode,
        procedureCode: this.wpCode,
      };
      const res = await getEwoAchieveConfigParam(list);
      if (res == null) {
        this.achieveList.type = 3;
        return;
      } else {
        this.achieveList = res;
      }
    },
    //获取用户列表 根据标签code（以及所属企业）
    async getSysUserList() {
      const params = {
        departmentCode: this["departmentCode"],
        labelCode: "project_person",
      };
      const res = await getSysUserList(params);
      this.approvalList = res;
    },
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    selectStartTime(v) {
      this.planStartTime = this.formatter(v);
      this.startTimeShow = false;
    },
    selectEndTime(v) {
      this.planEndTime = this.formatter(v);
      this.endTimeShow = false;
    },
    formatter(val) {
      let year = val.getFullYear(); //年
      let month = val.getMonth() + 1; //月
      let day = val.getDate(); //日
      let hours = val.getHours(); //小时
      let minutes = val.getMinutes(); //分钟
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (hours >= 0 && hours <= 9) {
        hours = `0${hours}`;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = `0${minutes}`;
      }
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    selectDefective(v) {
      if (v.length > 0) {
        this.defectiveText = this.defectiveList.toString();
      }
      this.defectiveShow = false;
    },
    headleItemRefresh() {
      this.getEwoAchieveConfigParam();
    },
    async onSubmit() {
      try {
        var _that = this;
        //处理生产人员
        const index = _that.rwPowerOojList.findIndex(
          (v) => v.nickname == _that.personnel
        );
        //处理审批人员，拿到id
        const index1 = _that.approvalList.findIndex(
          (v) => v.nickname == _that.roleName
        );
        //处理不良品项
        if (_that.defectiveList != []) {
          var defectiveIdList = [];
          for (var i = 0; i < _that.defectiveList.length + 1; i++) {
            !(function (ii) {
              const index = _that.defectiveItemObjList.findIndex(
                (v) => v.defectiveName == _that.defectiveList[ii]
              );
              if (index != -1) {
                defectiveIdList.push(_that.defectiveItemObjList[index].id);
              }
            })(i);
          }
        }
        const res = await this.$refs.customise.onModulSubmit();
        var params = {
          id: _that.workId,
          woId: _that.woId,
          woCode: _that.woCode,
          wpCode: _that.wpCode,
          departmentCode: this["departmentCode"],
          rwPowerId: _that.rwPowerOojList[index].id,
          goodProductCounts: _that.goodProductCounts,
          badProductCounts: _that.badProductCounts,
          defectiveItems: defectiveIdList.toString() || "",
          startTime: _that.planStartTime,
          endTime: _that.planEndTime,
          reviewState: 0, //新增的工单未审核为0
          wages: _that.totalPrice,
          type: _that.achieveList.type,
          price: _that.achieveList.price,
          reviewUserId: _that.approvalList[index].id,
          definitionProperties: res,
        };
        const res1 = await addWork(params);
        _that.$toast.success("添加成功");
        _that.$router.replace({ path: "/workDetails" });
        _that["work/closeWorkIngId"]();
      } catch (err) {
        this.$toast.fail("添加失败");
      }
    },
    headerItemDateShow(id) {
      this.dataId = id;
      this.dataShow = !this.dataShow;
    },
    headerItemDateChange(value) {
      if (this.moderTime.length > 1) {
        const index = this.moderTime.findIndex((v) => v.id == this.dataId);
        this.moderTime[index].values = value;
      } else {
        this.moderTime[0].values = value;
      }
      this.dataId = "";
      this.dataShow = !this.dataShow;
    },
  },
  watch: {
    goodProductCounts: function (val) {
      if (val == 0) {
        this.totalPrice = 0;
        this.total = parseInt(this.badProductCounts);
      } else {
        this.total =
          parseInt(this.badProductCounts) + parseInt(this.goodProductCounts);
        this.totalPrice = val * this.achieveList.price;
      }
    },
    badProductCounts: function (val) {
      if (val == 0) {
        this.total = parseInt(this.goodProductCounts);
      } else {
        this.total =
          parseInt(this.badProductCounts) + parseInt(this.goodProductCounts);
      }
    },
  },
  components: {
    scroll,
    customise,
  },
};
</script>
<style lang="less" scoped>
.report_work {
  width: 100vw;
  height: 100vh;
  .report_work_scroll {
    height: calc(100vh - 100px);
    .report_work_from {
      .report_work_from_header {
        width: 95%;
        box-sizing: border-box;
        margin: auto;
        margin-top: 40px;
        border-radius: 10px;
      }
    }
    .report_work_input {
      width: 95%;
      box-sizing: border-box;
      margin: auto;
      font-size: 30px;
      margin-top: 40px;
    }
    .report_work_input_details {
      width: 95%;
      box-sizing: border-box;
      margin: auto;
      margin-top: 40px;
      .report_work_personnel {
        margin-bottom: 20px;
        border-radius: 10px;
      }
      .report_work_numb {
        border-radius: 10px;
      }
    }
    .report_work_input_time {
      width: 95%;
      box-sizing: border-box;
      margin: auto;
      margin-top: 20px;
      border-radius: 10px;
      /deep/ .van-field__left-icon {
        color: red;
      }
    }
    .report_work_achievements {
      width: 95%;
      box-sizing: border-box;
      margin: auto;
      margin-top: 40px;
      margin-bottom: 40px;
      .report_work_achievements_item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 30px;
        .refresh {
          font-size: 30px;
          color: blue;
          display: flex;
          align-items: center;
          .icon{
            font-size: 40px;
          }
        }
      }
    }
    .report_work_calculation {
      width: 95%;
      box-sizing: border-box;
      margin: auto;
      border-radius: 10px;
      background-color: #fff;
      padding: 30px 30px;
      box-sizing: border-box;
      font-size: 30px;
      .report_work_wages {
        display: flex;
        align-items: center;
        .icon {
          color: blue;
          font-size: 40px;
        }
      }
      .report_work_total {
        margin-top: 30px;
        .report_work_total_yuan {
          color: blue;
        }
      }
    }
    .report_work_approval {
      width: 95%;
      box-sizing: border-box;
      margin: auto;
      border-radius: 10px;
      background-color: #fff;
      margin-top: 40px;
    }
    .color {
      width: 95%;
      box-sizing: border-box;
      margin: auto;
      border-radius: 10px;
      margin-top: 30px !important;
    }
    .submit {
      margin-top: 40px !important;
    }
  }
  .jurisdiction {
    height: 40vh;
    padding: 20px;
    box-sizing: border-box;
    .jurisdiction_headle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      div:nth-child(2) {
        color: #576b95;
      }
    }
    .van-radio {
      margin-top: 15px;
    }
    .van-checkbox {
      margin-top: 15px;
    }
  }
}
.jurisdiction_headle {
  font-size: 30px;
}
/deep/ .van-radio-group {
  margin-top: 40px;
  font-size: 30px;
}
/deep/ .van-checkbox-group {
  margin-top: 40px;
  font-size: 30px;
}
</style>
